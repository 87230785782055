/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledFormControlLabel = withStyles({
  root: {
    margin: 0,
  },
  label: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    color: '#242546',
  },
})(FormControlLabel);

const CustomFormControlLabel = ({ label, control, ...props }) => (
  <StyledFormControlLabel control={control} label={label} {...props} />
);

CustomFormControlLabel.propTypes = {
  label: PropTypes.string.isRequired,
  control: PropTypes.node.isRequired,
};

export default CustomFormControlLabel;
