import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const ChoiceButton = ({
  label,
  onClick,
  disabled = false,
  selected = false,
  style = {},
  ...props
}) => {
  const defaultStyles = {
    color: '#242546',
    fontSize: 12,
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 500,
    letterSpacing: '0.2px',
    // outline: selected ? '1px solid #007BFF' : 'none', // Highlight if selected
    border: selected
      ? '2px solid rgba(0, 0, 0, 0.75)'
      : '1px solid rgba(0, 0, 0, 0.23)',
    outline: 'none',
    ...style,
  };

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={defaultStyles}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {label}
    </Button>
  );
};

ChoiceButton.propTypes = {
  /** The text label to display on the button */
  label: PropTypes.string.isRequired,

  /** The function to call when the button is clicked */
  onClick: PropTypes.func,

  /** Whether the button is disabled */
  disabled: PropTypes.bool,

  /** Whether the button is selected */
  selected: PropTypes.bool,

  /** Additional inline styles to apply to the button */
  style: PropTypes.object,

  /** Any other props passed to the Material-UI Button component */
  props: PropTypes.object,
};

ChoiceButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  selected: false,
  style: {},
};

export default ChoiceButton;
