import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Down from '../../../../images/onboarding/practitioner/down.svg';
import Search from '../../../../images/onboarding/practitioner/search.svg';

/* eslint-disable react/jsx-props-no-spreading */

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Down />
    </components.DropdownIndicator>
  );

const SearchIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Search className="search-icon" />
    </components.DropdownIndicator>
  );

function Dropdown({
  label,
  input,
  meta,
  options,
  clearable,
  searchable,
  multi,
  width,
  disabled,
  required,
}) {
  const { error, touched } = meta;
  const { onBlur, value } = input;
  const className = required ? 'onboarding-label-required' : 'onboarding-label';
  const styles = {
    container: base => ({
      ...base,
      width: width || '200px',
    }),
    control: base => ({
      ...base,
      backgroundColor: 'rgba(240, 242, 245, 0.79)',
      border: 'none',
      borderRadius: '4px',
      height: '40px',
      boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      borderRadius: '4px',
      boxShadow: '0 0 10px 0 rgba(99, 140, 177, 0.2)',
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      cursor: 'pointer',
    }),
  };
  return (
    <div style={{ position: 'relative' }}>
      <p className={className}>{label}</p>
      <Select
        /* eslint-disable react/jsx-props-no-spreading */
        {...input}
        components={{
          DropdownIndicator: searchable ? SearchIndicator : DropdownIndicator,
        }}
        isClearable={clearable}
        isSearchable={searchable}
        onBlur={() => onBlur(value)}
        options={options}
        placeholder=""
        styles={styles}
        isMulti={multi}
        isDisabled={disabled}
        noOptionsMessage={() => (
          <FormattedMessage defaultMessage="No results found" />
        )}
      />
      {touched && error && <p className="onboarding-error">{error}</p>}
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  multi: PropTypes.bool,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: undefined,
  input: {},
  meta: {},
  options: [],
  clearable: false,
  searchable: false,
  multi: false,
  width: null,
  disabled: false,
};

export default Dropdown;
